import axios from 'axios';

const state = {
  user: null,
  token: null,
};
const getters = {
  isAuthenticated: state => !!state.user,    
  StateUser: state => state.user,
  UserRole: state => {
    if (state.user) {
      if (state.user.role === 5) {
        return "Super Admin"
      } else if (state.user.role === 4) {
        return "Library Admin"
      } else if (state.user.role === 3) {
        return "Integrator"
      } else if (state.user.role === 2) {
        return "Building Owner"
      } else {
        return "User"
      }
    } else {
      return "not logged in"
    }
    
  },
  Token: state => state.token
};
const actions = {
  async LogIn({commit}, User) {
    let response = await axios.post('/admin/users/login', User)
    await commit('setUser', response.data.user)
    await commit('setToken', response.data.token)
  },
  async LogOut({commit}){
    commit('LogOut')
  }
  
};
const mutations = {
  setUser(state, user){
    state.user = user
  },
  setToken(state, token){
    state.token = token
  },
  LogOut(state){
    state.user = null
    state.token = null
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};