<template>
  <v-container fluid class="tenants-page mt-5">
    <v-data-iterator
      :items="tenants"
      :search="search"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar flat height="90">    
          <v-icon large class="mx-4">mdi-menu</v-icon>         
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Add Tenant
          </v-btn>
        </v-toolbar>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">New Tenant</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="editedItem.name"
                  label="Tenant name"
                ></v-text-field>
                <v-switch
                  v-model="editedItem.tenant_status"
                  inset
                  :label="editedItem.tenant_status ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:default="props">
        <v-row class="mt-5">
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="12"
          >
          <v-card
              class="mx-auto"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1 primary--text">
                    {{item.name}}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar>
                  <v-icon x-large color="primary">mdi-shield-home</v-icon>
                </v-list-item-avatar>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  {{userCount(item)}}
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  {{siteCount(item)}}
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>  
  </v-container>
</template>

<script>
  export default {
    name: 'Tenants',
    data: () => ({
      tenants: [],
      search: '',
      loading: true,
      dialog: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        tenant_status: true
      },
      defaultItem: {
        name: '',
        tenant_status: true
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Tenant' : 'Edit Tenant'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Tenants')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/admin/tenants')
        this.tenants = response.data.tenants
        this.loading = false
      },
      userCount (tenant) {
        if (tenant.users.length == 1) {
          return tenant.users.length + " user"
        } else if (tenant.users.length > 1) {
          return tenant.users.length + " users"
        } else {
          return "no users"
        }
      },

      siteCount (tenant) {
        if (tenant.sites.length == 1) {
          return tenant.sites.length + " site"
        } else if (tenant.sites.length > 1) {
          return tenant.sites.length + " sites"
        } else {
          return "no sites"
        }
      },

      editItem (item) {
        this.editedIndex = this.tenants.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        const Tenant = new FormData();
        Tenant.append("name", this.editedItem.name);
        Tenant.append("tenant_status", this.editedItem.tenant_status);
        if (this.editedIndex > -1) {
          Tenant.append("id", this.editedItem.id);
          let response = await this.$http.put('/admin/tenants', Tenant)
          Object.assign(this.tenants[this.editedIndex], response.data.tenant)
          this.$toast.success("Tenant Updated");
        } else {
          let response = await this.$http.post('/admin/tenants', Tenant)
          this.tenants.push(response.data.tenant)
          this.$toast.success("Tenant Created");
        }
        this.close()
      },
    },
  }
</script>