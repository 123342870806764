<template>
  <v-container fluid class="accesses-page mt-5">
    <v-row>
      <v-col cols="6">
        <v-data-table
          :headers="headers"
          :items="internalAccesses"
          :search="search"
          :loading="loading"
          key="id"
          sort-by="id"
          class="elevation-1"
          :items-per-page="12"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            > 
              <v-spacer></v-spacer>
              <v-toolbar-title>
                Internal User Access
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.validity`]="{ item }">
            <span v-if="item.validity">
              {{ item.validity | moment($store.getters.dateFormat) }}
            </span>
            <span v-else>
              never
            </span>
          </template>
          <template v-slot:[`item.last_visit`]="{ item }">
            <span v-if="item.last_visit">
              {{ item.last_visit | moment($store.getters.dateTimeFormat) }}
            </span>
            <span v-else>
              never
            </span>
          </template>
          <template v-slot:[`item.user_id`]="{ item }">
            {{ showUser(item.user_id) }}
          </template>
          <template v-slot:[`item.user_role`]="{ item }">
            {{ $showSiteRole(item) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.active" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="editItem(item)" class="mr-2" color="info">mdi-pencil-lock</v-icon>
              </template>
              <span>Edit Access</span>
            </v-tooltip>
            <v-tooltip v-if="item.active" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="deleteItem(item)" color="error">mdi-lock</v-icon>
              </template>
              <span>Revoke Access</span>
            </v-tooltip>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="editItem(item)" color="primary">mdi-lock-open-variant</v-icon>
              </template>
              <span>Grant Access</span>
            </v-tooltip>
            
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" width="600px">
          <v-card>
            <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
              <v-toolbar-title class="headline primary--text">Access Right</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="save" color="primary">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>Save</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="close" color="error">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.user_role"
                    :items="roles"
                    item-text="text"
                    item-value="value"
                    label="Role"
                    prepend-icon="mdi-account-lock"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="dp"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedValidityDate"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        label="Expires on"
                        prepend-icon="mdi-calendar-alert"
                        persistent-hint
                        hint="leave blank for unlimited access"
                        @click:clear="editedItem.validity=null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.validity"
                      @input="dp = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <v-data-table
          :headers="headers"
          :items="externalAccesses"
          :loading="loading"
          key="id"
          sort-by="id"
          class="elevation-1"
          :items-per-page="12"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            > 
              <v-spacer></v-spacer>
              <v-toolbar-title>
                External User Access
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="newItem()">Share Site</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.validity`]="{ item }">
            <span v-if="item.validity">
              {{ item.validity | moment($store.getters.dateFormat) }}
            </span>
            <span v-else>
              never
            </span>
          </template>
          <template v-slot:[`item.last_visit`]="{ item }">
            <span v-if="item.last_visit">
              {{ item.last_visit | moment($store.getters.dateTimeFormat) }}
            </span>
            <span v-else>
              never
            </span>
          </template>
          <template v-slot:[`item.user_id`]="{ item }">
            {{ showUser(item.user_id) }}
          </template>
          <template v-slot:[`item.user_role`]="{ item }">
            {{ $showSiteRole(item) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.active" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="editItem(item)" class="mr-2" color="info">mdi-pencil-lock</v-icon>
              </template>
              <span>Edit Access</span>
            </v-tooltip>
            <v-tooltip v-if="item.active" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="deleteItem(item)" class="mr-2" color="error">mdi-lock</v-icon>
              </template>
              <span>Revoke Access</span>
            </v-tooltip>
            <v-tooltip v-if="item.active && !isUserActive(item.user_id)" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="reinviteUser(item)" class="mr-2" color="info">mdi-email-sync</v-icon>
              </template>
              <span>Resend Invitation</span>
            </v-tooltip>
            <v-tooltip v-if="!item.active" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click.stop="editItem(item)" color="primary">mdi-lock-open-variant</v-icon>
              </template>
              <span>Grant Access</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-model="newDialog"
          width="600px"
          v-if="sitePermission>1"
        >
          <v-card>
            <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
              <v-toolbar-title class="headline primary--text">Share site</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="create" color="primary" :disabled="saveDisabled">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>Save</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="close" color="error">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.email"
                      label="email"
                      prepend-icon="mdi-shield-account"
                      v-on:blur="findInvitee"
                      :disabled="editedIndex > -1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="newUser">
                    <p>This user is not registered in our database, sharing this site will send a registration invite to the user.</p>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.user_role"
                      :items="roles"
                      item-text="text"
                      item-value="value"
                      label="Role"
                      prepend-icon="mdi-account-lock"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      v-model="dp1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedValidityDate"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          label="Expires on"
                          prepend-icon="mdi-calendar-alert"
                          hint="leave blank for permanent access"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.validity"
                        @input="dp1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SiteAccesses',
    data: () => ({
      search: '',
      loading: true,
      users: [],
      headers: [
        { text: 'User', value: 'user_id' },
        { text: 'Last Visit', value: 'last_visit' },
        { text: 'Role', value: 'user_role' },
        { text: 'Expires On', value: 'validity' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' },
      ],
      roles: [
        { text: 'Reader', value: 1 },
        { text: 'Contributor', value: 2 },
        { text: 'Full Access', value: 3 },
      ],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        email: "",
        user_role: 0,
        validity: null,
        user_id: 0
      },
      defaultItem: {
        email: "",
        user_role: 0,
        validity: null,
        user_id: 0
      },
      dp: false,
      newDialog: false,
      saveDisabled: true,
      dp1: false,
      newUser: false,
    }),

    computed: {
      ...mapGetters(['site', 'StateUser', 'sitePermission']),
      computedValidityDate () {
        return this.editedItem.validity ? this.$moment(String(this.editedItem.validity)).format('DD/MM/YYYY') : ''
      },

      internalAccesses () {
        let accesses = []
        this.site.site_accesses.forEach((item) => {
          let userTenantId = this.users.find(el => el.id == item.user_id)?.tenant_id
          if (userTenantId == this.site.tenant_id) {
            accesses.push(item)
          }
        })
        return accesses
      },

      externalAccesses () {
        let accesses = []
        this.site.site_accesses.forEach((item) => {
          let userTenantId = this.users.find(el => el.id == item.user_id)?.tenant_id
          if (userTenantId != this.site.tenant_id) {
            accesses.push(item)
          }
        })
        return accesses
      }
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.site.name + ' > Access')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/global/user_list')
        this.users = response.data.users
        this.loading = false
      },

      showUser (id) {
        let user = this.users.find(obj => { return obj.id === id })
        if (user) {return user.email}
      },

      isUserActive (id) {
        let user = this.users.find(obj => { return obj.id === id })
        if (user) {return user.user_status}
      },

      newItem() {
        if (this.sitePermission == 3) {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.newDialog = true
          this.saveDisabled = false
          this.newUser = false
        }
      },

      editItem (item) {
        if (this.sitePermission == 3) {
          this.editedIndex = this.site.site_accesses.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.editedItem.email = this.showUser(this.editedItem.user_id)
          this.dialog = true
        }
      },

      async deleteItem (item) {
        let response = await this.$http.delete('/site/site_accesses', {params: {id: item.id}})
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateSiteAccess', response.data.site_access)
          this.$toast.success("Access Revoked")
        }
        this.close()
      },

      close () {
        this.dialog = false
        this.newDialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.saveDisabled = false
          this.newUser = false
        })
      },

      async findInvitee () {
        if (this.editedItem.email.length > 2) {
          const User = new FormData()
          User.append("email", this.editedItem.email)
          let response = await this.$http.post('/global/find_user', User)
          if (response.data.user === "not found") {
            this.saveDisabled = false
            this.newUser = true
          } else {
            this.editedItem.user_id = response.data.user.id
            this.saveDisabled = false
            this.newUser = false
          }
        }
      },

      async save () {
        const Access = new FormData()
        Access.append("user_role", this.editedItem.user_role)
        Access.append("validity", this.editedItem.validity)
        if (this.editedItem.validity == null) {
          Access.append("unlimited", true)
        } 
        if (this.editedIndex > -1) {
          Access.append("id", this.editedItem.id)
          let response = await this.$http.put('/site/site_accesses', Access)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$store.commit('updateSiteAccess', response.data.site_access)
            this.$toast.success("Site Access Updated")
            this.close()
          }
        } 
        
      },

      async create () {

        if (this.newUser) {
          const User = new FormData()
          User.append("email", this.editedItem.email)
          User.append("site_id", this.site.id)
          User.append("site_name", this.site.name)
          let response = await this.$http.post('/tenant/users/invite_user', User)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$toast.success("User Invited to sign up")
            this.editedItem.user_id = response.data.user.id
            this.users.push(response.data.user)
            this.newUser = false
          }
        }

        const Access = new FormData()
        Access.append("user_role", this.editedItem.user_role)
        Access.append("validity", this.editedItem.validity)
        Access.append("user_id", this.editedItem.user_id)
        Access.append("site_id", this.site.id)
        Access.append("inviter_id", this.StateUser.id)
        if (this.editedItem.validity == null) {
          Access.append("unlimited", true)
        } 
        let response = await this.$http.post('/site/site_accesses', Access)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('createSiteAccess', response.data.site_access)
          this.$toast.success("Site Access Created")
          this.close()
        }
        
      },

      async reinviteUser(item) {
        const Invite = new FormData()
        Invite.append("user_id", item.user_id)
        Invite.append("site_id", item.site_id)
        let response = await this.$http.post('/tenant/users/reinvite_user', Invite)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$toast.success("User's invitation resent")
        }
      },
    },
  }
</script>