<template>
  <v-card height="80vh">
    <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
      <v-toolbar-title class="headline primary--text">{{ formTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top v-if="editedIndex > -1">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="dialogDelete = true" color="error">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="save" color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="close" color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-tabs v-model="tab" fixed grow>
        <v-tab>Credentials</v-tab>
        <v-tab v-if="editedIndex > -1">Access rights</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="mt-5">
        
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.email" label="Email/Username*" required ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                  <v-text-field
                    v-if="editedIndex > -1"
                    v-model="editedItem.password"
                    hint="Leave blank to keep current password, if you edit your own password you will be logged out"
                    persistent-hint
                    label="Password"
                    type="password"
                  >
                  </v-text-field>
                  <v-text-field
                  v-else
                  v-model="editedItem.password"
                  label="Password*"
                  type="password"
                  required
                  ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                label="First name*"
                v-model="editedItem.fname"
                required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                  <v-text-field
                  label="Last name*"
                  v-model="editedItem.lname"
                  required
                  ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                v-model="editedItem.role"
                :items="tenantUserRoles"
                label="Role"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="editedItem.user_status"
                  inset
                  class="ml-5"
                  :label="editedItem.user_status ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="editedIndex > -1">
          <v-data-table
            :headers="headers"
            :items="tenantSites"
            class="elevation-1"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-img v-if="item.image.length > 0" :src="item.image" :alt="item.name" max-height="40px" max-width="40px" contain></v-img>
              <v-img v-else src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" :alt="item.name" max-height="40px" max-width="40px" contain></v-img>
            </template>
            <template v-slot:[`item.access`]="{ item }">
              <v-icon v-if="hasAccess(item.id)" color="primary" small>mdi-check</v-icon>
              <v-icon v-else color="error" small>mdi-close</v-icon>
            </template>
            <template v-slot:[`item.site_access`]="{ item }">
              {{showAccess(item.id)}}
            </template>
            <template v-slot:[`item.validity`]="{ item }">
              {{showAccessValidity(item.id)}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip v-if="hasAccess(item.id)" top>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" @click.stop="editAccess(item)" class="mr-2" color="info">mdi-pencil-lock</v-icon>
                </template>
                <span>Edit Access</span>
              </v-tooltip>
              <v-tooltip v-if="hasAccess(item.id)" top>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" @click.stop="deleteAccess(item)" color="error">mdi-lock</v-icon>
                </template>
                <span>Revoke Access</span>
              </v-tooltip>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" @click.stop="newAccess(item)" color="primary">mdi-lock-open-variant</v-icon>
                </template>
                <span>Grant Access</span>
              </v-tooltip>
              
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-dialog v-model="dialogDelete" width="600px">
      <v-card class="text-center py-3">
        <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
        <v-card-title>
          <v-spacer />
          <div class="text-center">
            <h3 class="headline error--text mb-0">Delete User: {{editedItem.email}}</h3>
            <h3 class="headline error--text mb-0">Are you Sure ?</h3>
          </div>
          <v-spacer />
        </v-card-title>
        <v-card-actions class="mt-5">
          <v-spacer />
          <v-btn @click="dialogDelete = false">Cancel</v-btn>
          <v-btn @click="deleteItem" color="error">Confirm</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAccess" width="600px">
      <v-card>
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">Access Right</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="saveAccess" color="primary">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="dialogAccess=false" color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="editedAccessItem.user_role"
                :items="siteRoles"
                item-text="text"
                item-value="value"
                label="Role"
                prepend-icon="mdi-account-lock"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-menu
                v-model="dp"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedValidityDate"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    label="Expires on"
                    prepend-icon="mdi-calendar-alert"
                    persistent-hint
                    hint="leave blank for unlimited access"
                    @click:clear="editedAccessItem.validity=null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedAccessItem.validity"
                  @input="dp = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>             
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'userCard',
    data: () => ({
      editedIndex: -1,
      editedItem: {
        username: '',
        password: '',
        email: '',        
        fname: '',
        lname: '',
        tenant_id: '',
        user_status: true,
        site_accesses: [],
      },
      defaultItem: {
        username: '',
        password: '',
        email: '',
        fname: '',
        lname: '',
        tenant_id: '',
        user_status: true,
        site_accesses: [],
      },
      tab: null,
      headers: [
        { text: '', value: 'image', sortable: false },
        { text: 'Site', value: 'name'},
        { text: 'Access', value: 'access'},
        { text: 'Site Role', value: 'site_access'},
        { text: 'Expires', value: 'validity'},
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' },
      ],
      searchSite: '',
      dialogDelete: false,
      dialogAccess: false,
      siteRoles: [
        {value: 1, text: 'Reader'},
        {value: 2, text: 'Contributor'},
        {value: 3, text: 'Full Access'},
      ],
      editedAccessIndex: -1,
      editedAccessItem: {
        site_id: '',
        user_id: '',        
        user_role: '',
        unlimited: true,
        validity: '', 
        active: false,
      },
      defaultAccessItem: {
        site_id: '',
        user_id: '',        
        user_role: '',
        unlimited: true,
        validity: null, 
        active: false,
      },
      dp: false,

    }),

    watch: {
      tenantSelectedUser(){
        this.initialize()
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit '+this.editedItem.fname+' '+this.editedItem.lname
      },
      computedValidityDate () {
        return this.editedAccessItem.validity ? this.$moment(String(this.editedAccessItem.validity)).format('DD/MM/YYYY') : ''
      },
      ...mapGetters(['tenantUserRoles', 'tenantUsers', 'tenantSites', 'tenantSelectedUser']),
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        if (!(JSON.stringify(this.tenantSelectedUser) === '{}')) {
          this.editedIndex = this.tenantUsers.indexOf(this.tenantSelectedUser)
          this.editedItem = Object.assign({}, this.tenantSelectedUser)
          this.editedItem.password = ''
        }
        this.loading = false
      },

      async deleteItem () {
        let response = await this.$http.delete('/tenant/users', {params: {id: this.editedItem.id}})
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('deleteTenantUser', response.data.user.id)
          this.$toast.success('User '+ response.data.user.id +' Deleted')
          this.close()
        }
      },

      close () {
        this.$store.commit('closeTenantUserCard')
        this.editedAccessIndex = -1
        this.tab = null
      },

      async save () {
        const User = new FormData();
        User.append("username", this.editedItem.email)
        User.append("email", this.editedItem.email)
        User.append("fname", this.editedItem.fname)
        User.append("lname", this.editedItem.lname)
        User.append("user_status", this.editedItem.user_status)
        User.append("tenant_id", this.editedItem.tenant_id)
        User.append("role", this.editedItem.role)
        if (this.editedIndex > -1) {
          User.append("id", this.editedItem.id);
          if (this.editedItem.password !== '') {User.append("password", this.editedItem.password)} 
          let response = await this.$http.put('/tenant/users', User)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$store.commit('updateTenantUser', response.data.user)
            this.$toast.success("User Updated")
            this.close()
          }
        } else {
          User.append("password", this.editedItem.password)
          let response = await this.$http.post('/tenant/users', User)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            let newUser = response.data.user
            newUser.site_accesses = []
            this.$store.commit('createTenantUser', newUser)
            this.$toast.success("User Created")
            this.close()
          }
        }
        
      },

      showAccess (site_id) {
        let site_access = this.tenantSelectedUser.site_accesses.find(o => o.site_id === site_id)
        if (site_access) {
          if (site_access.active) {
            if (site_access.user_role == 1) {
              return "Reader"
            } else if (site_access.user_role == 2) {
              return "Contributor"
            } else if (site_access.user_role == 3) {
              return "Full Access"
            }
          } else {
            return "Access Expired"
          }
        } else {
          return "No Access"
        }
      },

      hasAccess (site_id) {
        let site_access = this.tenantSelectedUser.site_accesses.find(o => o.site_id === site_id)
        if (site_access?.active) { return true } else { return false }
      },

      showAccessValidity (site_id) {
        let site_access = this.tenantSelectedUser.site_accesses.find(o => o.site_id === site_id)
        if (site_access) { 
          if (site_access.unlimited) {
            return 'Unlimited'
          } else {
            return this.$moment(String(site_access.validity)).format('DD/MM/YYYY')
          }
        } else { 
          return 'No Access' 
        }
      },

      newAccess (item) {
        let site_access = this.tenantSelectedUser.site_accesses.find(o => o.site_id === item.id)
        if (site_access) {
          this.editedAccessItem = site_access
          this.editedAccessIndex = site_access.id
        } else {
          this.editedAccessItem = this.defaultAccessItem
          this.editedAccessItem.site_id = item.id
          this.editedAccessItem.user_id = this.editedItem.id
          this.editedAccessIndex = -1
        }
        this.dialogAccess = true
      },

      editAccess(item) {
        let site_access = this.tenantSelectedUser.site_accesses.find(o => o.site_id === item.id)
        this.editedAccessItem = site_access
        this.editedAccessIndex = site_access.id
        this.dialogAccess = true
      },

      async deleteAccess(item) {
        let site_access = this.tenantSelectedUser.site_accesses.find(o => o.site_id === item.id)

        let response = await this.$http.delete('/site/site_accesses', {params: {id: site_access.id}})

        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateTenantSiteAccess', response.data.site_access)
          this.$toast.success("Site Access Revoked")
        }
      },

      async saveAccess() {
        const Access = new FormData()

        Access.append("user_role", this.editedAccessItem.user_role)

        if (this.editedAccessItem.validity) {
          Access.append("validity", this.editedAccessItem.validity)
          Access.append("unlimited", false)
        } else {
          Access.append("validity", null)
          Access.append("unlimited", true)
        }

        if (this.editedAccessIndex > -1) {
          Access.append("id", this.editedAccessItem.id)
          
          let response = await this.$http.put('/site/site_accesses', Access)

          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$store.commit('updateTenantSiteAccess', response.data.site_access)
            this.$toast.success("Site Access Updated")
          }
        } else {
          Access.append("site_id", this.editedAccessItem.site_id)
          Access.append("user_id", this.editedAccessItem.user_id)
          for (var pair of Access.entries()) {
              console.log(pair[0]+ ', ' + pair[1]); 
          }

          let response = await this.$http.post('/site/site_accesses', Access)

          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$store.commit('createTenantSiteAccess', response.data.site_access)
            this.$toast.success("Site Access Created")
          }
        }
        this.dialogAccess = false
      },


    }
  }
</script>